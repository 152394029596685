import request from '@/utils/request'
const requestApi = {
  wms_picks_list: '/wms/pick_list/',
  wms_picks_packer: '/wms/pick_list/packer/',
  wms_picks_picker: '/wms/pick_list/picker/',
  wms_picks_edit: '/wms/pick_list/',
  picks_list: '/wms/picks_id/'
}

export function picks_list (query) {
  return request({
    url: requestApi.wms_picks_list,
    method: 'get',
    params: query
  })
}
export function picks_list_id (query) {
  return request({
    url: requestApi.picks_list,
    method: 'get',
    params: query
  })
}

export function wms_picks_packer (data) {
  return request({
    url: requestApi.wms_picks_packer,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_picks_edit (data, id) {
  return request({
    url: requestApi.wms_picks_edit + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function wms_picks_patch (data, id) {
  return request({
    url: requestApi.wms_picks_edit + id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_picks_picker (data) {
  return request({
    url: requestApi.wms_picks_picker,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function picks_delete (picks_id) {
  return request({
    url: requestApi.wms_picks_delete + picks_id + '/',
    method: 'delete'
  })
}
