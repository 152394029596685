<template>
  <div>
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="5">
          <a-form-item label="名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="英文名称" >
            <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="编码" >
            <a-input v-decorator="['code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="原产地" >
            <a-input v-decorator="['original_place', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="海关编码" >
            <a-input v-decorator="['hs_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="单位" >
            <a-input v-decorator="['unit', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="开启保质期" >
            <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关"/>
          </a-form-item>
          <a-form-item label="保质期" >
            <a-input v-decorator="['shelf_life', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="保质期单位" >
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="关税税率" >
            <a-input v-decorator="['tariff_rate', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="消费税" >
            <a-input v-decorator="['consumption_tax_rate', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="增值税" >
            <a-input v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="激活" >
            <a-switch v-decorator="['is_active', {initialValue: false, valuePropName: 'checked'}]"/>
          </a-form-item>
          <a-form-item label="总量" >
            <a-input v-decorator="['total_count', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="序号" >
            <a-input-number :min="1" v-decorator="['serial', {initialValue: 1, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { goods_list_id, goods_update } from '@/api/c_wms_goods'
// 表单字段
const fields = ['id', 'name', 'en_name', 'code', 'original_place', 'hs_code', 'unit', 'open_self_life',
  'shelf_life', 'shelf_life_unit', 'tariff_rate', 'consumption_tax_rate', 'value_added_tax_rate', 'is_active', 'total_count', 'serial', 'remark'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      location: '',
      wms_goodss: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        console.log('--------------------', values)
        goods_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    handleGetInfo () {

    },
    wmsWarehouseList () {
      goods_list_id().then(res => {
        this.wms_goodss = res.data
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        formData.shelf_life_unit = data.shelf_life_unit.toString()
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
