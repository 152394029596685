import request from '@/utils/request'
const requestApi = {
  wms_goods_list: '/wms/goods/',
  wms_goods_create: '/wms/goods/',
  wms_goods_update: '/wms/goods/',
  wms_goods_delete: '/wms/goods/',
  goods_list: '/wms/goods_id/'
}

export function goods_list (query) {
  return request({
    url: requestApi.wms_goods_list,
    method: 'get',
    params: query
  })
}
export function goods_list_id (query) {
  return request({
    url: requestApi.goods_list,
    method: 'get',
    params: query
  })
}

export function goods_create (data) {
  return request({
    url: requestApi.wms_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_update (data, goods_id) {
  return request({
    url: requestApi.wms_goods_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function goods_delete (goods_id) {
  return request({
    url: requestApi.wms_goods_delete + goods_id + '/',
    method: 'delete'
  })
}
