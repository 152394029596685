<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="4" :sm="24">
            <a-form-item label="订单号">
              <a-input
                v-model="queryParam.order_no"
                placeholder="请输入订单号"
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="仓库名称">
              <a-input
                v-model="queryParam.wms_warehouse_name"
                placeholder="请输入仓库名称"
                @keyup.enter.native="$refs.table.refresh(true)"
                allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="拣货人">
              <a-select
                v-model="queryParam.pick_by_name"
                placeholder="请选择"
                allow-clear
                @search="get_users"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption">
                <a-select-option
                  :value="val.nickname"
                  v-for="(val, key) of this.userList"
                  :key="key">{{ val.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="打包人">
              <a-select
                v-model="queryParam.pack_by_name"
                placeholder="请选择"
                allow-clear
                @search="get_users"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option
                  :value="val.nickname"
                  v-for="(val, key) of this.userList"
                  :key="key">{{ val.nickname }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                <a-select-option
                  :value="key"
                  v-for="(val, key) of this.$Dictionaries.pick_status"
                  :key="key">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button @click="showPickerModal">
        <a-icon type="pick" />
        拣货人
      </a-button>
      <a-button @click="showPackerModal">
        <a-icon type="pack" />
        打包人
      </a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :rowSelection="options.rowSelection"
      :alert="options.alert"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record" v-if="(record.status !== 6)">
        <template>
          <a @click="handlePickPack(record)">拣货人&打包人</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">改状态</a>
        </template>
      </span>
    </s-table>
    <a-modal
      @cancel="handleCancel"
      v-model="selectvisible"
      title="请选择"
      ok-text="确认"
      cancel-text="取消"
      @ok="select_user">
      <strong>{{ picker_packer===2? '拣货人：':'打包人：' }}</strong>
      <a-select
        style="width: 80%"
        @change="handleSelectChange"
        v-model="user_id"
        @search="get_users"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        allowClear
      >
        <a-select-option :value="item.id" v-for=" (item, index) in userList" :key="index">
          {{ item.nickname }}
        </a-select-option>
      </a-select>
    </a-modal>

    <a-modal
      v-model="selectDoublevisible"
      title="请选择"
      ok-text="确认"
      cancel-text="取消"
      @ok="select_users"
      @cancel="cancel_users">
      <strong>拣货人：</strong>
      <a-select
        style="width: 80%"
        @change="handleSelectChange"
        v-model="select_picker_id"
        @search="get_users"
        option-filter-prop="children"
        :filter-option="filterOption"
        show-search
        allowClear>
        <a-select-option :value="item.id" v-for=" (item, index) in userList" :key="index">
          {{ item.nickname }}
        </a-select-option>
      </a-select>
      <br />
      <br />
      <strong>打包人：</strong>
      <a-select
        style="width: 80%"
        @change="handleSelectChange"
        v-model="select_packer_id"
        @search="get_users"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        allowClear>
        <a-select-option :value="item.id" v-for=" (item, index) in userList" :key="index">
          {{ item.nickname }}
        </a-select-option>
      </a-select>
    </a-modal>

    <a-modal
      v-model="select_status_visible"
      title="请选择状态"
      ok-text="确认"
      cancel-text="取消"
      @ok="select_status"
      @cancel="cancel_status">
      <a-select style="width: 90%" @change="handleSelectChange" v-model="select_status_data">
        <a-select-option
          v-for=" (val, key) in this.$Dictionaries.pick_status"
          :key="key"
          :value="key"
          :disabled="select_status_data>=key">
          {{ val }}
        </a-select-option>
      </a-select>
      <div v-if="select_status_data == 4 && show_logistics">
        <br />
        <strong v-if="false">物流：</strong>
        <a-select style="width: 90%" @change="handleSelectLogisticsChange" v-model="picked_logistics">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.pick_logistics"
            :key="key"
            :value="key">
            {{ val }}</a-select-option>
        </a-select>
      </div>
      <div v-if="picked_logistics !== '请选择'&& select_status_data == 4">
        <br/>
        <strong>重量：</strong>
        <a-input-number v-model="weight" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  picks_delete,
  picks_list,
  wms_picks_edit,
  wms_picks_packer,
  wms_picks_patch,
  wms_picks_picker
} from '@/api/c_wms_picks'
import { user_list } from '@/api/user'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      userList: [],
      select_status_visible: false,
      selectvisible: false,
      selectDoublevisible: false,
      select_edit_record: {},
      picker_packer: 0,
      loading: false,
      user_id: '请选择',
      select_picker_id: '请选择',
      select_status_data: '请选择',
      select_packer_id: '请选择',
      picked_logistics: '请选择',
      weight: 0,
      show_logistics: false,
      confirmLoading: false,
      // 创建窗口控制
      current_bill_id: 0,
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '仓库',
          align: 'center',
          dataIndex: 'wms_warehouse',
          ellipsis: true
        },
        {
          title: '拣货人',
          dataIndex: 'pick_by',
          width: 120,
          ellipsis: true
        },
        {
          title: '打包人',
          dataIndex: 'pack_by',
          width: 120,
          ellipsis: true
        },
        {
          title: '企业',
          align: 'center',
          ellipsis: true,
          dataIndex: 'corporation'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.pick_status[text]
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '280px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return picks_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: this.getCheckboxProps,
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.get_users('')
  },
  methods: {
    handleSelectChange (v) {
      this.select_status_data = v
      this.picked_logistics = '请选择'
      this.weight = 0
      this.show_logistics = true
    },
    handleSelectLogisticsChange (v) {
      this.picked_logistics = v
    },
    get_users (value) {
      user_list({ nickname: value }).then(({ data }) => {
        console.log(data, '我是发票列表')
        this.userList = data.entries || []
      })
    },
    filterOption (input, option) {
      // console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleCancel () {
      this.user_id = '请选择'
    },
    select_user () {
      if (this.user_id === '请选择') {
        this.$message.warning('请选择')
        return
      }
      if (this.picker_packer === 1) {
        const param = {
          pick_id_list: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.id
          }),
          packer_id: (this.user_id === undefined) ? -1 : this.user_id
        }
        wms_picks_packer(param).then((res) => {
          this.selectvisible = false
          if (res.code === 1000) {
            this.$refs.table.refresh()
          }
          this.user_id = '请选择'
        })
      } else if (this.picker_packer === 2) {
        const param = {
          pick_id_list: this.selectedRows.filter((item) => {
            return item.status !== 6
          }).map((item) => {
            return item.id
          }),
          picker_id: (this.user_id === undefined) ? -1 : this.user_id
        }
        wms_picks_picker(param).then((res) => {
          this.selectvisible = false
          if (res.code === 1000) {
            this.$refs.table.refresh()
          }
          this.user_id = '请选择'
        })
      }
    },
    cancel_users () {
      // this.select_picker_id = '请选择'
      // this.select_packer_id = '请选择'
    },
    select_users () {
      console.log(this.selectedRows)
      if (this.select_picker_id === '请选择' && this.select_packer_id === '请选择') {
        this.$message.warning('请选择')
        return
      }
      this.selectDoublevisible = false
      const param = {
        picker_id: (this.select_picker_id === '请选择' || this.select_picker_id === undefined) ? -1 : this.select_picker_id,
        packer_id: (this.select_packer_id === '请选择' || this.select_packer_id === undefined) ? -1 : this.select_packer_id
      }
      wms_picks_edit(param, this.select_edit_record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
        this.select_picker_id = '请选择'
        this.select_packer_id = '请选择'
      })
    },
    cancel_status () {
      this.select_status_visible = false
      this.picked_logistics = '请选择'
      this.select_status_data = '请选择'
      this.weight = 0
      this.show_logistics = false
    },
    select_status () {
      if (this.select_status_data === '请选择' || this.picked_logistics === '请选择') {
        this.$message.warning('请选择')
        return
      }
      let param = {
        status: this.select_status_data
      }
      if (this.select_status_data === '4') {
        param.weight = this.weight
        param.logistics_company = Number(this.picked_logistics)
      }
      wms_picks_patch(param, this.select_edit_record.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.select_status_visible = false
        this.picked_logistics = '请选择'
        this.select_status_data = '请选择'
        this.weight = 0
        this.show_logistics = false
      })
    },
    getCheckboxProps (record) {
      return {
        props: {
          disabled: !(record.status !== 2 || record.status !== 1)
        }
      }
    },
    showPackerModal () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请先选择拣货单')
        return
      }
      this.selectvisible = true
      this.picker_packer = 1
    },
    showPickerModal () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请先选择拣货单')
        return
      }
      this.selectvisible = true
      this.picker_packer = 2
    },
    hideModal () {
      this.selectvisible = false
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: this.getCheckboxProps,
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePickPack (record) {
      this.selectDoublevisible = true
      this.select_edit_record = record
      this.select_picker_id = record.pick_by_id === -1 ? '请选择' : record.pick_by_id
      this.select_packer_id = record.pack_by_id === -1 ? '请选择' : record.pack_by_id
    },
    handleEdit (record) {
      this.select_status_visible = true
      this.select_edit_record = record
      this.select_status_data = record.status.toString()
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      console.log(record.id)
      picks_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
